<template>
<div>
  <div class="block-404">
    <noData text="无相关数据，请在其他页面选择单一商品进行查看~" />
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import noData from '@/components/no-data'

export default defineComponent({
  name: '404',
  components: {
    noData
  }
})
</script>

<style lang="scss" scoped>
.block-404 {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  background: #f6f6f9 url("~@/assets/top-bar/bar-bottom.png") no-repeat;
  background-size: 100% auto;

  & > div {
    width: 997px;
    margin-left: 186px;
    background-color: #fff;
    border-radius: 4px;
    height: 346px;
  }
}
</style>
